<template>

  <survey-patients-form-modal v-model="showSurveyPatientForm" @after-save="onSaveSurveyPatientsForm" :survey_id="selectedSurvey ? selectedSurvey.id : null"></survey-patients-form-modal>

  <div v-if="!currentTaskGroup">
    <i class="el-icon el-icon-loading" style="font-size:3em;"></i>
  </div>
  <div v-if="currentTaskGroup">
    <el-row class="mt-4">
      <el-col :span="11">
        <h6 style="float:left;">Mitarbeiter/innen</h6> <el-tag @click="selectAllUser" type="warning" size="mini" style="cursor:pointer; float:right">Alle wählen</el-tag>
        <el-select size="medium" class="w-100" filterable multiple v-model="currentTaskGroup.users">
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.username"
            :value="item.id"
          />
        </el-select>
      </el-col>
      <el-col :span="10" :offset="1">
        <h6>Tätigkeit</h6>
        <el-select size="medium" class="w-100" filterable v-model="currentTaskGroup.survey_activity_id">
          <el-option label="Keine Auswahl" :value="null"></el-option>
          <el-option
            v-for="item in surveyActivities"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-col>
    </el-row>
    <el-row class="mt-4">
      <el-col :span="11">
        <h6>Startdatum</h6>
        <el-date-picker
          v-model="currentTaskGroup.start_date"
          class="w-100"
          type="date"
          format="DD.MM.YYYY"
          value-format="YYYY-MM-DD"
          placeholder="Datum auswählen">
        </el-date-picker>
      </el-col>
      <el-col :span="10" :offset="1">
        <h6>Startzeit</h6>
        <el-input v-model="currentTaskGroup.start_time"></el-input>
      </el-col>
    </el-row>
    <el-row class="mt-4">
      <el-col :span="22">
        <h6>Memo</h6>
        <el-input type="textarea" v-model="currentTaskGroup.description"></el-input>
      </el-col>
    </el-row>
    <el-row class="mt-4">
      <el-col :span="19">
        <h6>Studie</h6>
        <el-select size="medium" class="w-100" filterable v-model="currentTaskGroup.survey_id" :disabled="currentTaskGroup.id">
          <el-option label="Keine Auswahl" :value="null"></el-option>
          <el-option
            v-for="item in surveys"
            :key="item.id"
            :label="item.title_short"
            :value="item.id"
          />
        </el-select>
      </el-col>
      <el-col :span="2" :offset="1">
        <h6>&nbsp;</h6>
        <el-tooltip
          content="Probanden hinzufügen"
          placement="bottom"
        >
        <el-button :disabled="!selectedSurvey" @click="showSurveyPatientForm = true" type="warning" size="mini" class="w-100"><i class="el-icon el-icon-user"></i> <i class="el-icon el-icon-circle-plus"></i></el-button>
        </el-tooltip>
      </el-col>
    </el-row>

    <el-row class="mt-4" v-if="currentTaskGroup && selectedSurvey && currentTaskGroup.patient_tasks">
      <el-col :span="22">

        <el-table
          ref="surveyTablePatientTasks"
          :data="currentTaskGroup.patient_tasks"
          :default-sort = "{prop: 'patient.name', order: 'ascending'}"
          row-key="id"
          style="width: 100%"
          highlight-current-row
        >
          <el-table-column
            width="50">
            <template #default="scope">
             <el-checkbox v-model="scope.row.active"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            label="Name"
            sortable>
            <template #default="scope">
              {{scope.row.patient.name}}, {{scope.row.patient.firstname}}
            </template>
          </el-table-column>
          <el-table-column
            label="Geburtsdatum"
            width="150">
            <template #default="scope">
              {{ $filters.germanDate(scope.row.patient.birthday) }}
            </template>
          </el-table-column>
          <el-table-column label="Aufgabe" width="200">
            <template #default="scope">
              <el-select size="medium" class="w-100" filterable v-model="scope.row.survey_task_id" @change="scope.row.active = scope.row.survey_task_id !== null || scope.row.survey_status_id !== null">
                <el-option label="Keine Auswahl" :value="null"></el-option>
                <el-option
                  v-for="item in surveyTasks"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="Nr" width="70">
            <template #default="scope">
              <el-input class="w-100" v-model="scope.row.number"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Status">
            <template #default="scope">
              <el-select size="medium" class="w-100" filterable v-model="scope.row.is_done">
                <el-option label="Offen" :value="0"></el-option>
                <el-option label="Erledigt" :value="1"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
          width="70">
            <template #default="scope">
              <div class="text-right">
                <el-button size="mini" type="warning" @click="onRowDuplicatePatientTask(scope.row)" icon="el-icon-circle-plus"></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

      </el-col>
    </el-row>

    <el-row class="mt-4">
      <el-col :span="11">
        <h6>Enddatum</h6>
        <el-date-picker
          v-model="currentTaskGroup.end_date"
          class="w-100"
          type="date"
          format="DD.MM.YYYY"
          value-format="YYYY-MM-DD"
          placeholder="Datum auswählen">
        </el-date-picker>
      </el-col>
      <el-col :span="10" :offset="1">
        <h6>Endzeit</h6>
        <el-input v-model="currentTaskGroup.end_time"></el-input>
      </el-col>
    </el-row>


    <hr class="mt-3"/>
    <el-row>
      <el-col :span="16">
        <el-popconfirm
          title="Möchten Sie diese Aufgabe löschen?"
          confirmButtonText='Ja, löschen'
          confirmButtonType="danger"
          cancelButtonText='Nein'
          iconColor="red"
          @confirm="onDeleteTaskConfirmed">
          <template #reference>
            <el-button type="danger" icon="el-icon-delete"></el-button>
          </template>
        </el-popconfirm>
      </el-col>
      <el-col :span="8" class="text-right">
        <el-button type="warning" @click="onSaveSurveyTaskGroup">Aufgabe speichern</el-button>
      </el-col>
    </el-row>


  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
// import _ from "lodash";
import moment from "moment";
import _ from "lodash";
import SurveyPatientsFormModal from "../parts/SurveyPatientsFormModal.vue";

export default {
  name: "SurveyTaskGroupForm",
  components: {SurveyPatientsFormModal},
  props: ['surveytaskgroup'],
  data() {
    return {
      showSurveyPatientForm: false,
      currentTaskGroup: null,
      isSaving: false,
    }
  },
  mounted: async function () {
    this.init()
  },
  computed: {
    ...mapGetters('surveys', ['surveys', 'surveyTasks', 'currentPatientSurveyTask', 'surveyActivities', 'surveyStatus', 'patientSurveyStates', 'patientSurveyStateById']),
    ...mapGetters('users', ['users']),
    selectedSurvey: function () {
      if(!this.currentTaskGroup || !this.currentTaskGroup.survey_id || !this.surveys) return null
      return _.find(this.surveys, (s) => s.id == this.currentTaskGroup.survey_id)
    }
  },
  watch: {
    'surveytaskgroup': async function() {
      this.init()
    },
    'currentTaskGroup.survey_id': async function() {
      if(this.currentTaskGroup.id) return
      if(this.selectedSurvey) {
        this.getSurveyTasks({ survey: this.selectedSurvey.id })
      }
    },
  },
  methods: {
    ...mapActions('surveys', ['getSurveys', 'getSurveyTasks', 'getSurveyActivities', 'getSurveyStatus', 'saveSurveyTaskGroup']),
    ...mapActions('users', ['getUsers']),
    async init() {

      if(this.surveytaskgroup && this.surveytaskgroup.id) {
        this.currentTaskGroup = _.cloneDeep(this.surveytaskgroup)
        this.currentTaskGroup.users = this.surveytaskgroup.users.map((u) => u.user_id)
        this.currentTaskGroup.start_date = moment(this.surveytaskgroup.start_date).format("YYYY-MM-DD")
        this.currentTaskGroup.start_time = moment(this.surveytaskgroup.start_date).format("HH:mm")
        this.currentTaskGroup.end_date = this.currentTaskGroup.end_date ? moment(this.surveytaskgroup.end_date).format("YYYY-MM-DD") : null
        this.currentTaskGroup.end_time = this.currentTaskGroup.end_date ? moment(this.surveytaskgroup.end_date).format("HH:mm") : null
        this.getSurveyTasks({ survey: this.currentTaskGroup.survey_id })
        if(this.currentTaskGroup.patient_tasks.length) {
          for(const patientTask of this.currentTaskGroup.patient_tasks) {
            patientTask.active = true
          }
        }
      }
      else {
        this.currentTaskGroup = {
          users: [ ],
          survey_activity_id: null,
          survey_id: null,
          start_date: moment().format('YYYY-MM-DD'),
          start_time: moment().format('HH:mm'),
          end_date: null,
          end_time: null,
          patient_tasks: []
        }
        const user = await this.$auth.fetch()
        this.currentTaskGroup.users = [user.data.id]
      }

      await this.getUsers()
      await this.getSurveyActivities()
      await this.getSurveys()

    },
    selectAllUser() {
      this.currentTaskGroup.users = this.users.map((u) => u.id)
    },
    async onSaveSurveyTaskGroup() {

      if(!this.currentTaskGroup.start_date) {
        this.$notify({
          message: 'Geben Sie bitte ein gültiges Datum an (DD.MM.YYYY)',
          type: 'error',
          position: 'bottom-left'
        });
        return
      }

      if(!this.currentTaskGroup.start_time || (this.currentTaskGroup.start_time && !this.currentTaskGroup.start_time.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]/g))) {
        this.$notify({
          message: 'Geben Sie bitte eine gültige Start-Uhrzeit an (HH:mm)',
          type: 'error',
          position: 'bottom-left'
        });
        return
      }

      if(this.currentTaskGroup.end_time && !this.currentTaskGroup.end_time.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]/g)) {
        this.$notify({
          message: 'Geben Sie bitte eine gültige End-Uhrzeit an (HH:mm)',
          type: 'error',
          position: 'bottom-left'
        });
        return
      }


      const result = await this.saveSurveyTaskGroup(this.currentTaskGroup)
      if(!result) {
        this.$notify({
          message: 'Ein Fehler trat auf',
          type: 'error',
          position: 'bottom-left'
        });
      }
      this.$emit('after-save')
    },
    async onRowDuplicatePatientTask(row) {
      let newSurveyTasks = []
      let rowAdded = false
      for(const task of this.currentTaskGroup.patient_tasks) {
        newSurveyTasks.push(task)
        if(task.patient.id == row.patient.id && !rowAdded) {
          rowAdded = true
          newSurveyTasks.push({
            active: true,
            patient: row.patient,
            survey_task_id: row.survey_task_id,
            survey_status_id: row.status_id,
            is_done: 0,
            number: row.number
          })
        }
      }
      this.currentTaskGroup.patient_tasks = newSurveyTasks

    },
    async onRowDeletePatientTask(index) {
      this.currentTaskGroup.patient_tasks[index].active = false
    },
    onSaveSurveyPatientsForm(patients) {
      if(!patients) return
      for(const surveyPatient of patients) {
        this.currentTaskGroup.patient_tasks.push({
          active: true,
          patient: surveyPatient.patient,
          survey_task_id: null,
          survey_status_id: surveyPatient.status_id,
          is_done: 0,
        })
      }
    }

  }
}
</script>
<style scoped>
</style>
