<template>
  <div>
    <el-dialog
      width="55%"
      v-model="showForm"
      :before-close="closeDialog"
    >
      <survey-patients-form :survey_id="currentSurveyId" @after-save="onAfterSave"></survey-patients-form>
    </el-dialog>

  </div>
</template>

<script>
import SurveyPatientsForm from "./SurveyPatientsForm";

export default {
  name: "SurveyPatientsFormModal",
  props: ['modelValue', 'survey_id'],
  components: {SurveyPatientsForm},
  data() {
    return {
      currentSurveyId: null,
      showForm: false
    }
  },
  mounted: async function () {
    this.init()
  },
  watch: {
    'survey_id': function() {
      this.init()
    },
    'modelValue': function() {
      this.init()
    }
  },
  methods: {
    init() {
      this.showForm = this.modelValue
      this.currentSurveyId = this.survey_id
    },
    closeDialog() {
      this.showForm = false
      this.currentSurveyId = null
      this.$emit('update:modelValue', false)
    },
    onAfterSave(patients) {
      this.$emit("after-save", patients)
      this.closeDialog()
    }
  }
}
</script>
<style scoped>
</style>
