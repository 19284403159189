<template>
  <div>
  <h5>Proband/innen</h5>
    <el-row class="mt-3">
      <el-col :span="13">
        <h6>Studie</h6>
        <el-select size="medium" class="w-100" filterable v-model="selectedSurveyId">
          <el-option label="Keine Auswahl" :value="null"></el-option>
          <el-option
            v-for="item in surveys"
            :key="item.id"
            :label="item.title_short"
            :value="item.id"
          />
        </el-select>
      </el-col>
      <el-col :span="10" :offset="1">
        <h6>Proband/innen Status</h6>
        <el-select size="medium" class="w-100" filterable multiple v-model="patientFilter">
          <el-option
            v-for="item in patientSurveyStates"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
    </el-row>

  <el-row class="mt-2" v-if="selectedSurvey">
    <el-col :span="22">

      <el-table
        ref="surveyTablePatients"
        :data="surveyPatients"
        :default-sort = "{prop: 'patient.name', order: 'ascending'}"
        row-key="id"
        style="width: 100%"
        highlight-current-row
      >
        <el-table-column
          label="Name"
          sortable>
          <template #default="scope">
            {{scope.row.patient.name}}, {{scope.row.patient.firstname}}
          </template>
        </el-table-column>
        <el-table-column
          label="Geburtsdatum"
          width="150">
          <template #default="scope">
            {{ $filters.germanDate(scope.row.patient.birthday) }}
          </template>
        </el-table-column>
        <el-table-column
          label="Studien-ID">
          <template #default="scope">
            {{scope.row.patient_survey_id}}
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <div class="text-right">
              <i v-if="isPatientSelected(scope.row.id)" style="color:green; font-weight:bold;" class="el-icon el-icon-circle-check mr-2"></i><el-button type="warning" size="mini" @click="addOrRemovePatient(scope.row)"><span v-if="isPatientSelected(scope.row.id)">Entfernen</span><span v-else>Übernehmen</span></el-button>
            </div>
          </template>
        </el-table-column>

      </el-table>

    </el-col>
  </el-row>
    <el-row class="mt-4">
      <el-col :span="24" class="text-right">
        <el-button type="warning" @click="onSave">Probanden übernehmen</el-button>
      </el-col>
    </el-row>

  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";

export default {
  name: "SurveyPatientsForm",
  props: ['survey_id'],
  data() {
    return {
      selectedSurveyId: null,
      surveyPatients: [],
      selectedSurveyPatients: [],
      patientFilter: [ 1 ]
    }
  },
  mounted: async function () {
    if(!this.surveys) await this.getSurveys()
    if(!this.surveyStatus) await this.getSurveyStatus()
    this.init()
  },
  computed: {
    ...mapGetters('surveys', ['surveys', 'surveyTasks', 'currentPatientSurveyTask', 'surveyActivities', 'surveyStatus', 'patientSurveyStates', 'patientSurveyStateById']),
    selectedSurvey: function () {
      if(!this.selectedSurveyId || !this.surveys) return null
      return _.find(this.surveys, (s) => s.id == this.selectedSurveyId)
    }
  },
  watch: {
    'survey_id': function() {
      this.init()
    },
    'patientFilter': async function() {
      this.filterSurveyPatients()
    },
    'selectedSurveyId': async function() {
      this.filterSurveyPatients()
    }
  },
  methods: {
    ...mapActions('surveys', ['getSurveys', 'getSurveyTasks', 'getSurveyActivities', 'getSurveyStatus', 'saveSurveyTaskGroup']),
    init() {
      if(this.survey_id) this.selectedSurveyId = this.survey_id
    },
    filterSurveyPatients() {
      this.surveyPatients = []
      if(this.selectedSurvey) {
        for(const surveyPatient of this.selectedSurvey.patients) {
          if(this.patientFilter.length > 0 && this.patientFilter.indexOf(surveyPatient.status_id) === -1) continue
          this.surveyPatients.push(surveyPatient)
        }
      }
    },
    onSave() {
      this.$emit('after-save', this.selectedSurveyPatients)
      this.selectedSurveyPatients = []
    },
    addOrRemovePatient(row) {
      if(this.isPatientSelected(row.id)) {
        this.selectedSurveyPatients = _.filter(this.selectedSurveyPatients, (p) => p.id !== row.id)
      }
      else {
        this.selectedSurveyPatients.push(row)
      }
    },
    isPatientSelected(id) {
      return _.find(this.selectedSurveyPatients, (p) => p.id == id)
    }
  }
}
</script>

<style scoped>

</style>
