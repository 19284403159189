<template>
  <div>
    <el-dialog
      width="60%"
      v-model="showForm"
      :before-close="closeDialog"
    >

      <survey-task-group-form :surveytaskgroup="currentSurveyTaskGroup" @after-save="onAfterSave"></survey-task-group-form>
    </el-dialog>

  </div>
</template>

<script>
import SurveyTaskGroupForm from "./SurveyTaskGroupForm";

export default {
  name: "SurveyTaskGroupFormModal",
  props: ['modelValue', 'surveytaskgroup'],
  components: {SurveyTaskGroupForm},
  data() {
    return {
      currentSurveyTaskGroup: null,
      showForm: false
    }
  },
  mounted: async function () {
    this.init()
  },
  watch: {
    'surveytaskgroup': function() {
      this.init()
    },
    'modelValue': function() {
      this.init()
    }
  },
  methods: {
    init() {
      this.showForm = this.modelValue
      this.currentSurveyTaskGroup = this.surveytaskgroup
    },
    closeDialog() {
      this.showForm = false
      this.currentSurveyTaskGroup = null
      this.$emit('update:modelValue', false)
    },
    onAfterSave() {
      this.$emit("after-save")
      this.closeDialog()
    }
  }
}
</script>
<style scoped>
</style>
